import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(186,0,177,0.8)",
    },
    secondary: {
      main: "#FD2B88",
      blue: "#17174A",
      grey1: "#82889C",
      greyBlue: "#434966",
      yellowDark: "#8B7424",
      black: "#020826",
      green: "#00D498",
      red: "#FF0040",
      pink: "#FF0083",
      gray: "#929AB3",
      purple: "#6945FC",
      grey400: "#98A2B3",
      grey500: "#667085",
      GrayNew: "#EDECF5",
    },
    error: {
      main: "rgba(255,0,64,0.6)",
    },
    background: {
      default: "#09092D",
      white: "#FFFFFF",
      ltYellow: "#FCEFC1",
      lightBlue: "#F3F4F7",
      lightPink: "#FFE3F2",
      ltGreen: "#E3F8FA",
      ltRed: "#FFDBDB",
      ltPurple: "#F1EEFF",
    },
  },
  typography: {
    fontFamily: "Manrope",
    h1: {
      fontSize: 34,
      lineHeight: 50 / 34,
      fontWeight: 600,
    },
    h2: {
      fontSize: 30,
      lineHeight: 41 / 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 26,
      lineHeight: 34 / 26,
      fontWeight: 400,
    },
    h4: {
      fontSize: 24,
      lineHeight: 33 / 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      lineHeight: 24 / 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 20,
      lineHeight: 27 / 20,
      fontWeight: 400,
    },
    p1: {
      fontSize: 15,
      lineHeight: 26 / 15,
      fontWeight: 400,
    },
    p2: {
      fontSize: 14,
      lineHeight: 22 / 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 13,
      lineHeight: 18 / 13,
      letterSpacing: 0.2,
      fontWeight: 700,
      textTransform: "unset",
    },
    c1: {
      fontSize: 13,
      lineHeight: 20 / 13,
      fontWeight: 500,
    },
    c2: {
      fontSize: 12,
      lineHeight: 17 / 12,
      fontWeight: 600,
    },
    label: {
      fontSize: 11,
      lineHeight: 15 / 11,
      fontWeight: 600,
    },
  },
  shadows: ["none", "none"],
  overrides: {},
});

export default theme;
